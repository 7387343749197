// @ts-nocheck
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import IconComponent from '../../themes/IconComponent'

// assets
const EditIcon = (props) => <IconComponent asset="/icons/edit.svg" {...props} testId="edit-icon" />

/**
 * Taken from https://materialdesignicons.com/icon/facebook-messenger
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const SvgEditIcon = (props) => <SvgIcon component={EditIcon} viewBox="0 0 12 12" {...props} />

export default SvgEditIcon
