// @ts-nocheck
import React, { useMemo } from 'react'
import { respondAbove, respondTo } from 'styles/mixins'
import { getTranslatedTextByKey } from 'utils/utils'
import styled from 'styled-components/macro'
import TypographyPro from 'themes/TypographyPro'
import NewAddressBox from 'components/home/NewHomePageHeader/NewAddressBox'
import ItemPopup from '../ItemPopup'
import AllStoresAreClosedBar from 'components/header/headers/LocalizedHeaderBar/AllStoresAreClosedBar'
import { inject, observer } from 'mobx-react'

const HomepageHeaderTitle = styled(TypographyPro)`
	text-align: center;
`

const TitleContainer = styled.div<{ $backdropEnabled: boolean }>`
	color: var(--strokeMenuTitle);
	padding: 25px 20px 20px 20px;
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	width: 100%;
	background: var(--clear);
	z-index: 1300;
	${({ $backdropEnabled }) => $backdropEnabled && `opacity: 0.1;`}

	${respondAbove.md`
		opacity:1;
	`}

  ${respondAbove.lg`
		width: unset;
		flex: 1;
		box-shadow: unset;
		z-index: 1203;
		padding: unset;
		padding-right: 40px;
		padding-left: 40px;

		${({ $itemPopupTop }) => $itemPopupTop && `flex: 0;`}
		${({ $itemPopupTop }) => $itemPopupTop && `flex-basis: 50%;`}
	`}

	${respondTo.md`
		${({ $itemPopupTop }) => $itemPopupTop && `display: none;`}
	`}
`

const Container = styled.div`
	display: flex;
	align-items: center;
	${respondTo.md`
		flex-direction: column;
	`}
`

const OrderTypeAndSearchContainer = styled.div`
	flex: 1;
	width: 100%;

	${respondAbove.lg`
		${({ $itemPopupTop }) => ($itemPopupTop ? 'flex: 0; flex-basis: 50%;' : 'padding: 15px;')}
		${({ $isTitleShown }) => !$isTitleShown && 'flex-basis: 100%;'}
	`}
`

const HomepageHeaderSubTitle = styled(TypographyPro)`
	text-align: center;
`

interface TitleAndAddressSearchProps {
	isPopupOpen: boolean
	backdropEnabled: boolean
	isStoreClosed: boolean
	titleContainerRef: React.RefObject<HTMLDivElement> | null
	isClientSideRendering: boolean
	itemPopupTop: boolean
	toggleItemPopup: (show: any) => void
}

const TitleAndAddressSearch = inject('User')(
	observer(
		({
			isPopupOpen,
			backdropEnabled,
			titleContainerRef,
			isStoreClosed,
			isClientSideRendering,
			itemPopupTop,
			toggleItemPopup,
			page,
			User,
		}: TitleAndAddressSearchProps) => {
			const translationKeys = useMemo(() => {
				if (page?.includes('menu')) {
					return {
						title: getTranslatedTextByKey('webviewFlow.menu.addressBoxTitle'),
						subTitle: getTranslatedTextByKey('webviewFlow.menu.addressBoxSubTitle'),
					}
				}
				if (page?.includes('deals')) {
					return {
						title: getTranslatedTextByKey('webviewFlow.deals.addressBoxTitle'),
						subTitle: getTranslatedTextByKey('webviewFlow.deals.addressBoxSubTitle'),
					}
				}

				return {
					title: getTranslatedTextByKey('webFlowHomePageHeader'),
					subTitle: getTranslatedTextByKey('webFlowHomePageSubHeader'),
				}
			}, [page, User.preferredLanguage])

			return (
				<Container>
					{(translationKeys?.title || translationKeys?.subTitle) && (
						<TitleContainer ref={titleContainerRef} $backdropEnabled={backdropEnabled} $itemPopupTop={itemPopupTop}>
							{translationKeys?.title && (
								<HomepageHeaderTitle variant="HomePageTitle" component="h1" data-testid="address-box-title">
									{translationKeys?.title}
								</HomepageHeaderTitle>
							)}
							{translationKeys?.subTitle && (
								<HomepageHeaderSubTitle variant="BodySmall" component="h2" data-testid="address-box-subtitle">
									{translationKeys?.subTitle}
								</HomepageHeaderSubTitle>
							)}
						</TitleContainer>
					)}

					<OrderTypeAndSearchContainer $itemPopupTop={itemPopupTop} $isTitleShown={translationKeys?.title || translationKeys?.subTitle}>
						{isClientSideRendering && itemPopupTop && (
							<ItemPopup newAddressBox toggleItemPopup={toggleItemPopup} isPopupOpen={isPopupOpen} itemPopupTop={itemPopupTop} />
						)}
						{isStoreClosed ? (
							<AllStoresAreClosedBar userLanguage={User.preferredLanguage} />
						) : (
							<NewAddressBox
								isPopupOpen={isPopupOpen}
								backdropEnabled={backdropEnabled}
								isStoreClosed={isStoreClosed}
								itemPopupTop={itemPopupTop}
							/>
						)}
					</OrderTypeAndSearchContainer>
				</Container>
			)
		}
	)
)
export default TitleAndAddressSearch
